import { useTranslation } from "react-i18next";
import React from "react";
import Flag from "react-world-flags";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  console.log(i18n.language);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="flex space-x-2">
      {/* Italian Button */}
      <button
        onClick={() => changeLanguage("it")}
        className={`${
          i18n.language === "it" ? "bg-black" : "bg-gray-800"
        } text-white font-medium py-2 px-4 rounded-lg hover:bg-black focus:outline-none transition duration-300 text-xs`}
      >
        <Flag size={20} width={40} height={15} code="it" />
        Italiano
      </button>

      {/* English Button */}
      <button
        onClick={() => changeLanguage("en")}
        className={`${
          i18n.language === "en" ? "bg-black-600" : "bg-gray-800"
        } text-white font-medium py-2 px-4 rounded-lg hover:bg-black focus:outline-none transition duration-300 text-xs`}
      >
        <Flag size={20} width={40} height={15} code="us" />
        English
      </button>
    </div>
  );
};

export default LanguageSwitcher;
