import { Icon, LatLngTuple } from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const position: LatLngTuple = [45.5366, 9.2774];

const icon = new Icon({
  iconUrl: "/marker-icon-2x.png",
  iconSize: [35, 35], // size of the icon
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

const ContactPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        <title>Eugenio Dallari - Contatto</title>
        <meta
          name="description"
          content="Eugenio Dallari, contattami direttamente nel mio studio, Email: eudallari@hotmail.it"
        />
        <meta
          name="keywords"
          content="dipinti, installazioni, artista, arte, speciale, studio, biografia, virus"
        />
      </Helmet>

      {/* Main Contact Section */}
      <div className="bg-black text-gray-300 pt-36 pb-24">
        <div className="max-w-6xl mx-auto px-6 lg:px-8">
          {/* Contact Header */}
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-white">{t("Contact")}</h2>
          </div>

          {/* Contact Information */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Email */}
            <div className="flex flex-col items-center justify-center space-y-4">
              <h3 className="text-2xl font-semibold text-white">
                {t("Email")}
              </h3>
              <p className="text-lg">eudallari@hotmail.it</p>
            </div>

            {/* Phone */}
            <div className="flex flex-col items-center justify-center space-y-4">
              <h3 className="text-2xl font-semibold text-white">
                {t("Phone")}
              </h3>
              <p className="text-lg">+39 331 315 3175</p>
            </div>
          </div>

          {/* Map Section */}
          <div className="mt-16">
            <div className="h-[300px] w-full overflow-hidden rounded-lg shadow-lg">
              <MapContainer
                center={position}
                zoom={13}
                scrollWheelZoom={false}
                style={{ height: "100%", width: "100%" }}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker icon={icon} position={position}>
                  <Popup>{t("I am here")}</Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
