import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "./layout";
import BioPage from "./pages/Bio";
import ContactPage from "./pages/Contact";
import { IndexPage } from "./pages/Index";
import { InstallationsPage } from "./pages/Installations";
import PaintingsPage from "./pages/Paintings";

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<IndexPage />} />
          <Route path="/bio" element={<BioPage />} />
          <Route path="/paintings" element={<PaintingsPage />} />
          <Route path="/installations" element={<InstallationsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
