import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { PaintingItem, paintings } from "../data/PaintingsData";

const PaintingsPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* Page Metadata */}
      <Helmet>
        <title>Eugenio Dallari - Dipinti</title>
        <meta
          name="description"
          content="Eugenio dallari, i miei dipinti e le mie opere"
        />
        <meta
          name="keywords"
          content="dipinti, installazioni, artista, arte, speciale, studio, biografia, virus"
        />
      </Helmet>

      {/* Page Content */}
      <div className="bg-black text-white py-32">
        <div className="container mx-auto px-4">
          {paintings.map((item: PaintingItem, index: number) => (
            <div
              key={index}
              className="mb-12 flex flex-col md:flex-row items-center gap-6 bg-black/100 border border-gray-900 rounded-lg shadow-lg overflow-hidden lg:h-[500px]"
            >
              {/* Painting Image */}
              <div className="w-full md:w-1/2">
                <img
                  src={`/images/works/${item.image}`}
                  alt={item.title}
                  className="object-cover w-full h-full"
                />
              </div>

              {/* Painting Details */}
              <div className="w-full md:w-1/2 p-6">
                <h3 className="text-2xl font-bold mb-4 text-gray-300">
                  {i18n.language === "it" ? item.title : item.titleEn}
                </h3>
                <p className="text-gray-400 mb-4">
                  {t(item.material)} - {item.description} ({item.productionDate}
                  )
                </p>

                {/* Single or Multiple Image Gallery */}
                {item.images && item.images.length === 1 && (
                  <img
                    src={item.images[0].original}
                    alt={item.title}
                    className="rounded-lg shadow-md"
                  />
                )}
                {item.images && item.images.length > 1 && (
                  <ImageGallery
                    items={item.images}
                    showThumbnails={true}
                    showPlayButton={false}
                    showFullscreenButton={false}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PaintingsPage;
