import { Helmet } from "react-helmet";
import { Fragment } from "react/jsx-runtime";
import Installations from "../containers/Installations";

export const InstallationsPage = () => {
  return (
    <Fragment>
      {/* SEO Metadata */}
      <Helmet>
        <title>Eugenio dallari - Installazioni</title>
        <meta
          name="description"
          content="Eugenio dallari , lei mie installazioni artistiche , guarda le mie installazioni multimediali"
        />
        <meta
          name="keywords"
          content="dipinti,installazioni,artista,arte,speciale,studio,biografia,virus"
        />
      </Helmet>

      {/* Main Contact Section */}
      <div className="bg-black text-gray-300">
        <Installations />
      </div>
    </Fragment>
  );
};
