import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Works: "Works",
      "Acrilico su tela": "Acrylic on canvas",
      "Acrilico su carta": "Acrylic on paper",
    },
  },
  it: {
    translation: {
      Phone: "Telefono",
      Works: "Lavori",
      Language: "Lingua",
      Contact: "Contatti",
      Paintings: "Dipinti",
      Installations: "Installazioni",
      Exhibitions: "Mostre",
      Contacts: "Contatti",
      "I am here": "Sono qui",
      Education: "Percorso formativo",
      "Acrilico su tela": "Acrilico su tela",
      Biography: "Biografia",
      curated: "a cura",
      by: "di",
      "Link online art show": "Link mostra online",
      "Born in": "Nato a",
      "Lives and works in": "Vive e lavora a",
      "Collective exhibition": "Mostra collettiva",
      "Personal exhibition": "Mostra personale",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: localStorage.getItem("language")
      ? localStorage.getItem("language")!
      : "it",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
