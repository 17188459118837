import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

// Artist Bio Component
const BioPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-black text-gray-300 py-32">
      <Helmet>
        <title>Eugenio Dallari - Bio</title>
        <meta
          name="description"
          content="Eugenio Dallari, leggi la biografia e le referenze artistiche di e.c.d bio: A.C.M.E., Umberto Boccioni, Università Statale (MI), Accademia di Belle Arti di Brera ItalyAmo, Save madness."
        />
        <meta
          name="keywords"
          content="dipinti, installazioni, artista, arte, studio, biografia, Collective exhibition, Mostra, Mostre"
        />
      </Helmet>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-8">
        {/* Biography Section */}
        <section className="mb-12">
          <h2 className="text-4xl font-bold text-white mb-4">
            {t("Biography")}
          </h2>
          <ul className="list-disc list-inside text-lg">
            <li>
              {t("Born in")} Segrate 1994, {t("Lives and works in")} Cologno
              Monzese (MI)
            </li>
          </ul>
        </section>

        {/* Education Section */}
        <section className="mb-12">
          <h2 className="text-4xl font-bold text-white mb-4">
            {t("Education")}
          </h2>
          <ul className="list-disc list-inside text-lg">
            <li>2008-2013 l.a.s. U. Boccioni</li>
            <li>2013-2016 A.C.M.E. (MI)</li>
            <li>2016-2017 Università Statale (MI)</li>
            <li>2017-2020 Accademia di Belle Arti di Brera (MI)</li>
          </ul>
        </section>

        {/* Exhibitions Section */}
        <section>
          <h2 className="text-4xl font-bold text-white mb-4">
            {t("Exhibitions")}
          </h2>
          <ul className="list-disc list-inside space-y-4 text-lg">
            <li>
              2014 {t("Collective exhibition")} “Art Out” C.s. Cantiere,{" "}
              {t("Milan")}- {t("curated")} {t("by")} Lodovico Manera
            </li>
            <li>
              2015 {t("Collective exhibition")} ItalyAmo - Vernissage Just
              Cavalli Club, {t("Milan")} - {t("curated")} {t("by")} Giovanna
              Gioia Passarella
            </li>
            <li>
              2016 {t("Collective exhibition")} Mostra il Mostro – N.d.a. –{" "}
              {t("curated")} {t("by")} Elisa Gaia Girani e Francesco Conti (
              {t("Milan")})
            </li>
            <li>
              2018 La conferenza degli uccelli – artepassante in collab (
              {t("with")}) Dual Band - {t("curated")} {t("by")} Roberta Secchi,
              Simone Lampis e Cristina Negro ({t("Milan")})
            </li>
            <li>
              2019 Performer festival della peste, Virus! – fondazione
              Lazzaretto – {t("curated")} {t("by")} Roberta Secchi, Simone
              Lampis e Cristina Negro ({t("Milan")})
            </li>
            <li>
              2020 “Stiamo facendo un mondo” – Performance nella Chiesa di San
              Carpoforo ({t("Milan")}) – {t("curated")} {t("by")} Gabriele di
              Matteo
            </li>
            <li>
              2020 Save Madness – Fondazione Lazzaretto – {t("curated")}{" "}
              {t("by")} Roberta Secchi, Simone Lampis e Cristina Negro – Online
              performance (MI)
            </li>
            <li>
              2021 Muselmann, {t("a tribute to")} Aldo Carpi – {t("curated")}{" "}
              {t("by")} Stefano Pizzi, Barbara Nahmad e Vittoria Coen –{" "}
              {t("Memorial")} Shoah Milano
            </li>
            <li>
              2021 Neimand is Perfect, {t("a tribute to")} Al Hansen –{" "}
              {t("curated")} {t("by")} Gabriele di Matteo, Bibbe Hansen, Steve
              Piccolo, Antonio Cioffi, Pau Masclans – Online performance
            </li>
            <li>
              2022 “AMOR VACUI” {t("Personal exhibition")} TERMINUS DIGITAL ART;
              Via Pietro Custodi 8, {t("Milan")} - {t("curated")} {t("by")}{" "}
              Pierpaolo Ceccarini, Franco Zanetti, Paolo Lagazzi
            </li>
            <li>
              2023 A VERY LONG SAILING TRIP - Ex Chiesa di San Carpoforo (Via
              Formentini, 12 – ({t("Milan")})) Live performance {t("curated")}{" "}
              {t("by")} Gabriele Di Matteo con Filippo Zoli, Ernesto Raimondi,
              Andrea Bertolin, Pau Masclans, Josse Renda, Eugenio Dallari e
              Martina Fichera
            </li>

            <li>
              2024 Curatorship - Casa Museo Emilio Tadini{" "}
              {t("Archive in collaboration with ")}
              Melina Scalise e Federicapaola Capecchi (MI)
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default BioPage;
