import React, { Fragment } from "react";
import IndexHero from "../components/Hero";
import Installations from "../containers/Installations";

export const IndexPage = () => {
  return (
    <Fragment>
      <IndexHero />
      <Installations />
    </Fragment>
  );
};
