import React from "react";
import { FaHeart } from "react-icons/fa";
export const Footer = () => {
  return (
    <footer className="bg-black text-white py-6 max-w-6xl mx-auto">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <nav className="mb-4 md:mb-0">
            <a
              href="https://www.iubenda.com/termini-e-condizioni/11805238"
              className="text-gray-400 hover:text-white transition-colors duration-300"
              title="Termini e Condizioni"
            >
              Terms & Conditions
            </a>

            <a
              href="https://www.iubenda.com/privacy-policy/11805238"
              className="text-gray-400 hover:text-white transition-colors duration-300 ml-5"
              title="Privacy Policy"
            >
              Privacy Policy
            </a>
          </nav>
          <div className="credits">
            <span className="text-gray-400 flex items-center">
              © {new Date().getFullYear()} &nbsp; made with &nbsp;
              <FaHeart className="text-red-600" /> &nbsp; by Alfredo Dallari
              Sergio
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
