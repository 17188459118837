import { motion, MotionValue, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";

const IndexHero = () => {
  return (
    <section className="bg-white">
      <Hero />
    </section>
  );
};

const Hero = () => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const scale = useTransform(
    scrollYProgress,
    [0, 0.65, 0.8, 1],
    [1, 1, 0.9, 1.25]
  );
  const rotate = useTransform(
    scrollYProgress,
    [0, 0.25, 1],
    ["0deg", "0deg", "60deg"]
  );
  const top = useTransform(scrollYProgress, [0, 0.25], ["80%", "0%"]);
  const opacity = useTransform(scrollYProgress, [0, 0.125], [1, 0]);
  const logoScale = useTransform(scrollYProgress, [0, 0.8, 1], [0, 0, 1]);

  return (
    <div ref={targetRef} className="relative z-0 h-[800vh] bg-black">
      <div className="sticky top-0 h-screen bg-black">
        <Copy opacity={opacity} />
        <Trippy rotate={rotate} top={top} scale={scale} />
        <OverlayLogo scale={logoScale} />
      </div>
    </div>
  );
};

const NUM_SECTIONS = 25;
const PADDING = `${100 / NUM_SECTIONS / 2}vmin`;

const generateSections = (
  count: number,
  color: string,
  scale: MotionValue,
  rotate: MotionValue
) => {
  if (count === NUM_SECTIONS) {
    return <></>;
  }

  const nextColor = color === "black" ? "#4B5563" : "black";

  return (
    <Section rotate={rotate} scale={scale} background={color}>
      {generateSections(count + 1, nextColor, scale, rotate)}
    </Section>
  );
};

const Trippy = ({
  rotate,
  scale,
  top,
}: {
  rotate: MotionValue;
  scale: MotionValue;
  top: MotionValue;
}) => {
  return (
    <motion.div
      style={{ top }}
      className="absolute bottom-0 left-0 right-0 overflow-hidden bg-black"
    >
      {generateSections(0, "black", scale, rotate)}
    </motion.div>
  );
};

const Section = ({
  background,
  scale,
  children,
  rotate,
}: {
  background: string;
  scale: MotionValue;
  rotate: MotionValue;
  children?: JSX.Element;
}) => {
  return (
    <motion.div
      className="relative h-full w-full origin-center"
      style={{
        background,
        scale,
        rotate,
        padding: PADDING,
      }}
    >
      {children}
    </motion.div>
  );
};

const Copy = ({ opacity }: { opacity: MotionValue }) => {
  return (
    <motion.div
      style={{ opacity }}
      // Padding top + 56px to accommodate for navbar height
      className="relative flex h-4/5 flex-col items-center justify-center gap-4 overflow-hidden bg-black p-4 pt-[calc(56px_+_16px)] text-white mb-20"
    >
      <h1 className="text-center text-5xl font-black md:text-7xl">
        ART IS EASY
      </h1>
      <div className="absolute -left-28 -top-28 h-56 w-56 rotate-45 bg-gray-600" />
      <div className="absolute -bottom-24 -right-24 h-48 w-48 rotate-45 bg-gray-600" />
    </motion.div>
  );
};

const OverlayLogo = ({ scale }: { scale: MotionValue }) => {
  return (
    <motion.div
      style={{ scale }}
      className="pointer-events-none absolute inset-0 z-[5] grid place-content-center"
    >
      <img
        src="/image-final.jpg"
        className="object-cover w-[90%] h-[90%] mx-auto"
      />
    </motion.div>
  );
};

export default IndexHero;
