import TWFOUR from "../assets/img/works/image/24.jpg";
import SVTEN from "../assets/img/works/image/17.jpg";
import FT from "../assets/img/works/image/14.jpg";
import TW from "../assets/img/works/image/20.jpg";
import TWT from "../assets/img/works/image/22.jpg";
import ONE from "../assets/img/works/image/1.jpg";
import SEVEN from "../assets/img/works/image/7.jpg";
import FIVE from "../assets/img/works/image/5.jpg";
import FOUR from "../assets/img/works/image/4.jpg";
import THREE from "../assets/img/works/image/3.jpg";
import SIX from "../assets/img/works/image/6.jpg";
import EIGHT from "../assets/img/works/image/8.jpg";
import NTEN from "../assets/img/works/image/19.jpg";
import FITEN from "../assets/img/works/image/15.jpg";
import TWFIVE from "../assets/img/works/image/25.jpg";
import THIRDTY from "../assets/img/works/image/30.jpg";

import video from "../assets/video/woman.mp4";

export interface WorkItem {
  title: string;
  titleEn: string;
  material?: string;
  materialEn?: string;
  productionDate: string;
  youtubeLink?: string | boolean;
  video?: string;
  link?: string;
  haiku: string;
  haikuEn: string;
  tribute?: string;
  subtitle: string;
  subtitleEn?: string;
  place: string;
  workType: string;
  moreDescription: string;
  description: string;
  descriptionEn?: string;
  image: string;
  images?: { original?: string; src?: string }[];
  marginTop?: number | string;
  movieId?: string | number;
}

export const works: WorkItem[] = [
  {
    image: "23.jpg",
    movieId: "EHNNWDR0dLg",
    youtubeLink: "",
    workType: "installation",
    material: "Performance",
    materialEn: "Performance",
    title: "Qui e Ora",
    titleEn: "Here and now",
    haikuEn:
      "I think things don’t exist.\nA glass, a man, a hen for example, are not really a glass, a man, a hen, they are only the verification of possibility of existence of a glass, of a man, of a hen.\n For things to exist, they must be eternal, immortal. Only in this way would they cease to be only the verification of certain possibilities and would become existing things",
    haiku:
      "Penso che le cose non esistano.\n" +
      "Un bicchiere, un uomo, una gallina per esempio, non sono veramente un bicchiere, un uomo, una gallina, sono soltanto la verifica delle possibilità di esistenza di un bicchiere, di un uomo, di una gallina.\n" +
      "Perchè le cose possano esisttere bisognerebbe che fossero eterne, immortali.\n" +
      "Solo così cesserebbero di essere unicamente la verifica di certe possibilità e diverrebbero cose esistenti",
    tribute: "Gino de Dominicis",
    subtitle: "QUI E ORA, dello spazio e della forma.",
    subtitleEn: "HERE AND NOW, of space and form.",
    description:
      "Spazio definito di 50x70x100 cm. circa. Delineare questo spazio è la necessità personale di creare un ambiente che esista solo grazie alla mia presenza, e che cessi di esistere con la mia assenza.\nL’ opera è anche il tentativo di raggiungere il grado più estremo dell’altrazione, una scultura che non necessiti dei sensi per essere percepita, che esista solo nella mente dell’osservatore e che prenda corpo attraverso la sua immaginaizone.",
    descriptionEn:
      "It's a defined space of 50x70x100 cm. Outlining this space is the personal need to create an environment that only exists thanks to my presence. The work is also an attempt to reach the most extreme degree of attraction, a sculpture that does not require the senses to be perceived, that exists only in the mind of the observer",
    moreDescription: "",
    productionDate: "2020",
    place: "Milano",
    images: [
      {
        original: SVTEN,
      },
      {
        original: TWFOUR,
      },
      {
        original: FT,
      },
      {
        original: TW,
      },
      {
        original: TWT,
      },
    ],
  },
  {
    image: "28.png",
    movieId: "EHNNWDR0dLg",
    youtubeLink: "",
    workType: "installation",
    material: "Tempera su carta",
    materialEn: "Poster paint on paper",
    title: "Quanto dura una lampadina?",
    titleEn: "How long does a light bulb last?",
    subtitle: "",
    descriptionEn: "",
    description: "",
    haiku:
      "L’artista che si rifiuta di imitare la natura nella minuzia delle sue particolarità,\n e che si vota alla vasta generalità della favola tragica, non può tuttavia evitare di metterci di fronte alla particolarità dell’individuo, del suo sogno e del suo tormento personali.\n Quest’arte si sviluppa quindi nella dimensione di un idealismo soggettivo: inventa un mondo separato, uno spettacolo mentale, su fondo di tenebra.",
    haikuEn:
      "The artist who refuses to imitate nature in the minutiae of its particularities,\n and who vows to the vast generality of the tragic fable, cannot however avoid putting ourselves in front of the particularity of the individual, of his dream and his personal torment.\n This art therefore develops in the dimension of a subjective idealism: it invents a separate world, a mental spectacle, on a background of darkness.",
    moreDescription: "",
    link: "http://mediagallery.comune.milano.it/cdm/objects/changeme:119709/datastreams/dataStream17013153558306831/content",
    productionDate: "2021",
    place: "Milano",
    tribute: "Jean Starobinski",
    images: [
      {
        original: FITEN,
      },
    ],
  },
  {
    image: "10.jpg",
    title: "Someone Left",
    titleEn: "Someone Left",
    workType: "installation",
    material: "Carta, adesivo",
    materialEn: "Paper, sticker",
    productionDate: "2019",
    place: "Milano",
    movieId: "s1rRYraub2o",
    marginTop: "100",
    youtubeLink: "https://youtu.be/s1rRYraub2o",
    haikuEn:
      "Lama trafigge \nIl corpo sottile \nBella carta bianca\n\nCono di carta \nSul capo appoggia\n Nascondendoti al cielo",
    haiku:
      "Lama trafigge \nIl corpo sottile \nBella carta bianca\n\nCono di carta \nSul capo appoggia\n Nascondendoti al cielo",
    subtitle:
      "Workshop with Boris Schwencke on paper uses within artistic manifestations - improv performance.",
    subtitleEn:
      "Workshop with Boris Schwencke on paper uses within artistic manifestations - improv performance.",
    description:
      "Opera realizzata con l’esclusivo utilizzo della carta, esplorando le possibilità che questo materiale concede in rela- zione alla sua plasticità e al movimento del corpo.",
    descriptionEn:
      "Work created with the exclusive use of paper. It aim to exploring the possibilities that this material grants in relation to its plasticity within body movement.\n",
    moreDescription: "",
    tribute: "",
    images: [
      {
        original: ONE,
      },
      {
        original: THREE,
      },
      {
        original: FOUR,
      },
      {
        original: FIVE,
      },
      {
        original: SIX,
      },
      {
        original: SEVEN,
      },
      {
        original: EIGHT,
      },
      {
        original: NTEN,
      },
    ],
  },
  {
    image: "31.jpg",
    workType: "installation",
    productionDate: "2022",
    place: "Milano",
    movieId: "WtA_Th0QO6M",
    youtubeLink: "https://youtu.be/WtA_Th0QO6M",
    subtitle: "",
    subtitleEn: "",
    moreDescription: "",
    tribute: "",
    material: "Tecnica mista",
    materialEn: "Mixed technique",
    title: "270 Volti",
    titleEn: "270 Faces",
    haiku:
      "Between the idea\n" +
      "And the reality\n" +
      "Between the motion\n" +
      "And the act\n" +
      "Falls the Shadow",
    haikuEn:
      "Between the idea\n" +
      "And the reality\n" +
      "Between the motion\n" +
      "And the act\n" +
      "Falls the Shadow",
    description: "150x70 c.a.",
    descriptionEn: "150x70 c.a.",
    images: [{ original: THIRDTY }],
  },
  {
    image: "21.jpg",
    movieId: "EHNNWDR0dLg",
    youtubeLink: "https://youtu.be/EHNNWDR0dLg",
    workType: "installation",
    material: "Performance",
    materialEn: "Performance",
    title: "Group Performance (The Owners)",
    titleEn: "Group Performance (The Owners)",
    haikuEn:
      "Butchery chains knives wax spatula\n Masochism waxing apron blood\n bonnet latex gloves Sadism Light",
    haiku:
      "Macelleria catene coltelli cera spatola\n Masochismo ceretta grembiule sangue\n cuffietta guanti latex Sadismo Luce",
    subtitle:
      "Art Performance inspired by Caryl Churchill's work: “The Owners”",
    subtitleEn:
      "Art Performance inspired by Caryl Churchill's work: “The Owners”",
    description:
      "Performance realizzata in workshop con Francesco Bianchi sul teatro al femminile, accademia di Brera 2019, tratto da The Owners, una piece di 2 atti e 14 scene sull’ossessione di potere e sugli stereotipi di genere nelle coppie tradizionali. scritta e prodotta a Londra dalla drammaturga Caryl Churchill nel 1972.\nPerformer: Stefano Melissa - Aurora Giancarli - Giulia Longoni - Emma Dotti - Eugenio Costantino Dallari Irene Galli - Marisol Blandon Guapacha - Camilla Rocchi - Leo Cogliati - Rebecca Rizzato\n",
    descriptionEn:
      "Performance aroused from Francesco Bianchi’s workshop in collaboration with Donata Lazzarini on female theatre.\nBased on “The Owners”. written and produced in London by Caryl Churchill in 1972.  Play in 2 acts and 14 scenes on the theme of power obsession and gender stereotypes in traditional couples.\nPerformer: Stefano Melissa - Aurora Giancarli - Giulia Longoni - Emma Dotti - Eugenio Costantino Dallari Irene Galli - Marisol Blandon Guapacha - Camilla Rocchi - Leo Cogliati - Rebecca Rizzato\n",
    moreDescription: "still da video - colore - audio",
    tribute: "",
    productionDate: "31/10/19 -> 21/11/19",
    place: "Milano",
    images: [
      {
        src: FITEN,
      },
    ],
  },
  {
    image: "26.jpg",
    movieId: "_6J8madAsHU",
    youtubeLink: "https://youtu.be/_6J8madAsHU",
    workType: "installation",
    material: "Performance",
    materialEn: "Performance",
    titleEn: "SILUET-US",
    title: "SILUET-US",
    haikuEn:
      "Un’ energia universale che attraversa tutto:\ndall’insetto all’uomo, dall’uomo allo spettro,\n dallo spettro alla pianta, dalla pianta alla galassia",
    haiku:
      "Un’ energia universale che attraversa tutto:\ndall’insetto all’uomo, dall’uomo allo spettro,\n dallo spettro alla pianta, dalla pianta alla galassia",
    subtitle: "Performance insipired by Ana Mendieta’s Siluetas (1973-1980)",
    subtitleEn: "Performance insipired by Ana Mendieta’s Siluetas (1973-1980)",
    description:
      "Workshop Femminisssmmm 2 my theatre a cura di Maria Rosa Sossai, studio e reenactment della modalità espressive delle artiste attive durante gli anni 70’ del secolo scorso. indagine sull’appartenenza alla terra durante la rivoluzione digitale in contesto urbano.",
    descriptionEn:
      "Workshop Feminisssmmm 2 my theater workshop curated Maria Rosa Sossai, study and reenactment of the expressive\nmodality of the artists active during the 70s of the last century. Survey on belonging to the land during the\ndigital revolution in an urban context.",
    moreDescription: "",
    tribute: "Eugenio C. Dallari - Beatrice Algeri - Irene Galli",
    productionDate: "2019/2020",
    place: "",
    images: [
      {
        original: TWFIVE,
      },
    ],
  },
  {
    image: "27.png",
    movieId: "EHNNWDR0dLg",
    youtubeLink: false,
    video: video,
    workType: "installation",
    material: "Videomake",
    materialEn: "Videomake",
    title: "Mommy Dearest Slap",
    titleEn: "Mommy Dearest Slap",
    haikuEn: "",
    haiku: "",
    subtitle:
      "”La donna è il negro del mondo“ cantava  John Lennon con la Plastic Ono Band. Il titolo del brano era stato coniato in origine da Yōko Ono.",
    subtitleEn:
      "”Woman is the Negro of the World“ John Lennon sang with the Plastic Ono Band. The title of the song was originally coined by Yōko Ono",
    description:
      "Una sera un uomo di teatro chiese a Jean Genet di scrivere una commedia per un gruppo di attori negri, lui si chiese: Ma che cosa è poi un negro?\n E per prima cosa di che colore sono i negri? \nNelle note di prefazione della sua opera intitolata: “I negri”, Genet specifica: “Questa commedia scritta da un bianco, è destinata a un pubblico di bianchi.\n Nello sfortunato caso in cui venga rappresentata di fronte a un pubblico di neri, una persona bianca dovrà ad ogni rappresentazione sedere in prima fila, vestito in maniera formale, in abito da cerimonia: gli attori reciteranno per questa persona.\n Un occhio di bue dovrà essere costantemente acceso su questo simbolico bianco.\n Se nessun bianco accetta di rivestire questo ruolo, all'ingresso saranno distribuite maschere da bianco a tutti gli spettatori neri. E se tutti i neri rifiutano di indossarla, si ricorrerà a un manichino”",
    descriptionEn:
      "One evening a man of the theater asked Jean Genet to write a play for a group of dark skin actors, he asked himself: But what is a black man? what color are the N word called people?\nIn the preface to his work entitled: “The Negroes”, Genet specifies: “This comedy written by a white man is intended for a white audience.\n In the unfortunate case of being performed in front of a black audience, a white person will have to sit in the front row at each performance, dressed in a formal manner, in formal dress: the actors will play for this person. A bull's-eye will have to be constantly lit on him.\n If no whites agree to play this role, white masks will be distributed to all black spectators at the entrance.\n And if all blacks refuse to wear it, a mannequin will be used”",
    moreDescription: "",
    productionDate: "2018",
    place: "Milano",
    images: [],
  },
  {
    image: "black",
    movieId: "TuF4MbsfN90",
    youtubeLink: "https://youtu.be/TuF4MbsfN90",
    workType: "installation",
    material: "Performance",
    materialEn: "Performance",
    titleEn: "Hangover 01",
    title: "Hangover 01",
    haikuEn:
      "Imagine the morning after a hangover, afternoon in bed with the blinds closed and the birds giving no respite, you will feel what I and other millions of party monsters have felt.",
    haiku:
      "Tornare a casa la mattina con i postumi della festa, il pomeriggio a letto con le persiane chiuse e gli uccelli che non danno tregua, sentirai quello che abbiamo provato io e altri milioni di party monsters.",
    subtitle: "",
    subtitleEn: "",
    description:
      "Questa installazione side specific è immaginata per riportare nelle condizioni alle quali io e tante altre persone ci siamo trovati a vivere durante il nostro percorso adolescenziale, e successivamente anche nella vita adulta; a causa della perdita del ritmo circadiano coadiuvato dal abuso di sostanze psicotrope di varia natura: Tornare a casa e voler solo spegnere il cervello. Chiudersi al buio in una stanza col mondo fuori ancora sveglio, con gli uccelli che cantano dal mattino sconvolgendo la mente come un bosco a settembre. Questa è installazione e pensata per portare lo spettatore nello stesso stato di quiete angosciante.",
    descriptionEn:
      "This side-specific installation intends to take the viewer back to the conditions in which I and many other people found themselves living during an adolescent journey, and subsequently also in adult life; due to the loss of the circadian rhythm aided by the abuse of psychotropic substances of various kinds: Going home and just wanting to turn the brain off. To do so, lock yourself in the dark in a room with the world outside still awake, with the birds singing from the morning upsetting the mind like a forest in September. This is installation and designed to bring the viewer into the same state of distressing stillness.",
    moreDescription: "",
    tribute: "Eugenio C. Dallari",
    productionDate: "2023",
    place: "",
    images: [],
  },
];
