// Importing images from assets
import TW from "../assets/img/works/image/painting12.jpg";
import FT from "../assets/img/works/image/painting14.jpg";
import TONE from "../assets/img/works/image/painting21.jpg";

// Type definition for a painting item
export interface PaintingImage {
  original: string;
}

export interface PaintingItem {
  image: string;
  youtubeLink: string;
  workType: "painting" | "installation" | string;
  material: string;
  title: string;
  titleEn: string;
  haiku: string;
  description: string;
  moreDescription: string;
  productionDate: string;
  place: string;
  images?: PaintingImage[];
}

// Array of paintings
export const paintings: PaintingItem[] = [
  {
    image: "painting9.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Paolo Lagazzi e Daniela Tomerini in Vacanza.",
    titleEn: "Paolo Lagazzi e Daniela Tomerini holidays.",
    haiku: "",
    description: "30x20 c.m.",
    moreDescription: "",
    productionDate: "2020",
    place: "",
    images: [],
  },
  {
    image: "painting20.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "post produzione su fotografia digitale",
    title: "La chiaroveggenza (omaggio a Renè Magritte)",
    titleEn: "Clairvoyance (homage to Renè Magritte)",
    haiku: "",
    description: "",
    moreDescription: "",
    productionDate: "2022",
    place: "",
    images: [{ original: TONE }],
  },
  {
    image: "painting23.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "tecnica mista su tela",
    title: "il disastro dell’LZ 129 Hindenburg",
    titleEn: "the LZ 129 Hindenburg disaster",
    haiku: "",
    description: "20x30  c.a.",
    moreDescription: "",
    productionDate: "2023",
    place: "",
    images: [],
  },
  {
    image: "painting24.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "tecnica mista su tela",
    title: "il disastro dell’LZ 129 Hindenburg",
    titleEn: "the LZ 129 Hindenburg disaster",
    haiku: "",
    description: "70x50  c.a.",
    moreDescription: "",
    productionDate: "2024",
    place: "",
    images: [],
  },
  {
    image: "painting26.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "tecnica mista su tela",
    title: "La stazione",
    titleEn: "The station",
    haiku: "",
    description: "20x30  c.a.",
    moreDescription: "",
    productionDate: "2023",
    place: "",
    images: [],
  },
  {
    image: "painting27.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "tecnica mista su tela",
    title: "Seduta psicoanalitica",
    titleEn: "Psychoanalytic session",
    haiku: "",
    description: "120x70  c.a.",
    moreDescription: "",
    productionDate: "2016",
    place: "",
    images: [],
  },
  {
    image: "painting8.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Vagone",
    titleEn: "Vagon",
    haiku: "",
    description: "120x70 c.m.",
    moreDescription: "",
    productionDate: "2017",
    place: "",
    images: [],
  },
  {
    image: "painting25.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "tecnica mista su tela",
    title: "Il lume della ragione",
    titleEn: "The light of reason",
    haiku: "",
    description: "35x60  c.a.",
    moreDescription: "",
    productionDate: "2024",
    place: "",
    images: [],
  },
  {
    image: "painting4.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Festa",
    titleEn: "Party",
    haiku: "",
    description: "120x80 c.m.",
    moreDescription: "",
    productionDate: "2013",
    place: "",
    images: [],
  },
  {
    image: "painting1.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Ponte",
    titleEn: "Bridge",
    haiku: "",
    description: "100x80 c.m.",
    moreDescription: "",
    productionDate: "2012",
    place: "",
    images: [],
  },
  {
    image: "painting3.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Strada",
    titleEn: "The Way",
    haiku: "",
    description: "45x30 c.m.",
    moreDescription: "",
    productionDate: "2010",
    place: "",
    images: [],
  },
  {
    image: "painting6.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Cascata",
    titleEn: "Fall",
    haiku: "",
    description: "120x80 c.m.",
    moreDescription: "",
    productionDate: "2015",
    place: "",
    images: [],
  },
  {
    image: "painting13.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Campo",
    titleEn: "Camp",
    haiku: "",
    description: "40x30 c.m.",
    moreDescription: "",
    productionDate: "2015",
    place: "",
    images: [],
  },
  {
    image: "painting22.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "tecnica mista su tela",
    title: "La Fratellanza",
    titleEn: "The brotherhood",
    haiku: "",
    description: "40x35 c.a.",
    moreDescription: "",
    productionDate: "2024",
    place: "",
    images: [],
  },
  {
    image: "painting18.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title:
      "Il laboratorio teatrale (Roberta Secchi, Cristina Negro, Simone Lampis).",
    titleEn:
      "Theatre laboratory (Roberta Secchi, Cristina Negro, Simone Lampis)",
    haiku: "",
    description: "40x30 c.m.",
    moreDescription: "",
    productionDate: "2020",
    place: "",
    images: [],
  },
  {
    image: "painting7.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Sdraiati a letto",
    titleEn: "Laying in bed",
    haiku: "",
    description: "100x80 c.m.",
    moreDescription: "",
    productionDate: "2016",
    place: "",
    images: [],
  },
  {
    image: "painting16.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Ritratto",
    titleEn: "Portrait",
    haiku: "",
    description: "30x40 c.m.",
    moreDescription: "",
    productionDate: "2011",
    place: "",
    images: [],
  },
  {
    image: "painting2.jpg",
    youtubeLink: "",
    workType: "painting",
    material: "Acrilico su tela",
    title: "Pattern (Mescolanza ottica)",
    titleEn: "Pattern",
    haiku: "",
    description: "50x50 c.m.",
    moreDescription: "",
    productionDate: "2013",
    place: "",
    images: [],
  },
];
