import "./App.css";
import { AppRoutes } from "./Routes";
import CookieConsentComponent from "./cookie/CookieConsent";
import "./i18n/i18n";
import "vanilla-cookieconsent/dist/cookieconsent.css";

function App() {
  return (
    <div className="bg-black">
      <CookieConsentComponent />
      <AppRoutes />
    </div>
  );
}

export default App;
