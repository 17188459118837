import { motion, useAnimate } from "framer-motion";
import {
  Dispatch,
  LegacyRef,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiArrowUpRight, FiInstagram, FiMenu } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import useMeasure from "react-use-measure";
import LanguageSwitcher from "../components/LanguageSwitcher";

const Header = () => {
  const [hovered, setHovered] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [scope, animate] = useAnimate();
  const navRef = useRef<HTMLDivElement | null>(null);

  const handleMouseMove = ({ offsetX, offsetY, target }: MouseEvent) => {
    const isNavElement = [...(target as any).classList].includes("glass-nav");

    if (isNavElement) {
      setHovered(true);

      const top = offsetY + "px";
      const left = offsetX + "px";

      animate(scope.current, { top, left }, { duration: 0 });
    } else {
      setHovered(false);
    }
  };

  useEffect(() => {
    navRef.current?.addEventListener("mousemove", handleMouseMove);

    return () =>
      navRef.current?.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <nav
      ref={navRef}
      onMouseLeave={() => setHovered(false)}
      style={{
        cursor: hovered ? "none" : "auto",
      }}
      className="glass-nav fixed left-0 right-0 top-0 z-10 mx-auto max-w-6xl overflow-hidden border-[1px] border-white/10 bg-black backdrop-blur md:left-6 md:right-6 md:top-6 md:rounded-2xl"
    >
      <div className="glass-nav flex items-center justify-between px-5 py-5">
        <div className="flex items-center gap-14">
          <Cursor hovered={hovered} scope={scope} />

          <Links />

          <span className="text-white text-2xl font-bold">
            <NavLink to={"/"}>Eugenio Dallari</NavLink>
          </span>
        </div>

        <ButtonsSocialAndLanguage setMenuOpen={setMenuOpen} />
      </div>

      <MobileMenu menuOpen={menuOpen} />
    </nav>
  );
};

const Cursor = ({ hovered, scope }: { hovered: boolean; scope: any }) => {
  return (
    <motion.span
      initial={false}
      animate={{
        opacity: hovered ? 1 : 0,
        transform: `scale(${
          hovered ? 1 : 0
        }) translateX(-50%) translateY(-50%)`,
      }}
      transition={{ duration: 0.15 }}
      ref={scope as LegacyRef<HTMLSpanElement> | undefined}
      className="pointer-events-none absolute z-0 grid h-[50px] w-[50px] origin-[0px_0px] place-content-center rounded-full bg-gradient-to-br from-gray-600 from-40% to-gray-400 text-2xl"
    >
      <FiArrowUpRight className="text-white" />
    </motion.span>
  );
};

const Links = () => (
  <div className="hidden items-center gap-2 md:flex">
    <GlassLink text="Bio" href="/bio" />
    <GlassLink text="Installazioni" href="/installations" />
    <GlassLink text="Dipinti" href="/paintings" />
    <GlassLink text="Contatti" href="/contact" />
  </div>
);

const GlassLink = ({ text, href }: { text: string; href: string }) => {
  return (
    <NavLink
      to={href}
      className={({ isActive }) => {
        return isActive
          ? "group relative overflow-hidden rounded-lg px-4 py-2 transition-transform hover:scale-105 scale-125 active:underline"
          : "group relative scale-100 overflow-hidden rounded-lg px-4 py-2 transition-transform hover:scale-105 active:scale-95";
      }}
    >
      <p className="relative z-10 text-white/90 transition-colors group-hover:text-white">
        {text}
      </p>
    </NavLink>
  );
};

const TextLink = ({ text, href }: { text: string; href: string }) => {
  return (
    <NavLink
      className={({ isActive }) => {
        return isActive
          ? "text-white/90 transition-colors hover:text-white underline"
          : "text-white/90 transition-colors hover:text-white";
      }}
      to={href}
    >
      {text}
    </NavLink>
  );
};

const ButtonsSocialAndLanguage = ({
  setMenuOpen,
}: {
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => (
  <div className="flex items-center gap-4">
    <div className="hidden md:block"></div>

    <a
      target="_blank"
      href="https://www.instagram.com/eudallari/"
      rel="noreferrer"
      className="relative scale-100 overflow-hidden rounded-lg bg-gradient-to-br from-gray-600 from-40% to-gray-400 px-4 py-2 font-medium text-black transition-transform hover:scale-105 active:scale-95"
    >
      <FiInstagram />
    </a>

    <LanguageSwitcher />

    <button
      onClick={() => setMenuOpen((pv) => !pv)}
      className="ml-2 block scale-100 text-3xl text-white/90 transition-all hover:scale-105 hover:text-white active:scale-95 md:hidden"
    >
      <FiMenu />
    </button>
  </div>
);

const MobileMenu = ({ menuOpen }: { menuOpen: boolean }) => {
  const [ref, { height }] = useMeasure();
  return (
    <motion.div
      initial={false}
      animate={{
        height: menuOpen ? height : "0px",
      }}
      className="block overflow-hidden md:hidden"
    >
      <div ref={ref} className="flex items-center justify-between px-4 pb-4">
        <div className="flex items-center gap-4">
          <TextLink text="Bio" href="/bio" />
          <TextLink text="Installazioni" href="/installations" />
          <TextLink text="Dipinti" href="/paintings" />
          <TextLink text="Contatti" href="/contact" />
        </div>
      </div>
    </motion.div>
  );
};

export default Header;
