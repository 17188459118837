import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaVideo, FaYoutube } from "react-icons/fa";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import YouTube from "react-youtube";
import { Video } from "reactjs-media";
import { WorkItem, works } from "../data/WorkData";

const Installations: React.FC = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [modalVideo, setModalVideo] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<WorkItem | null>(null);

  const opts = {
    height: "600",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  const setSelectedYoutube = (item: WorkItem) => {
    setSelectedItem(item);
    setModal(true);
  };

  const setSelectedVideo = (item: WorkItem) => {
    setSelectedItem(item);
    setModalVideo(true);
  };

  const toggle = () => setModal(!modal);
  const toggleVideo = () => setModalVideo(!modalVideo);

  const { i18n, t } = useTranslation();

  // Function to replace \n with <br /> tags
  const formatTextWithLineBreaks = (text: string) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="max-w-7xl mx-auto px-6 lg:px-8">
      <div className="bg-black text-white py-36">
        <div className="container mx-auto lg:px-6 px-4">
          {works.map((item: WorkItem, i: number) => (
            <div className="mb-8" key={i}>
              <div className="bg-gray-100 text-black rounded-lg overflow-hidden shadow-lg">
                <img
                  className="w-full"
                  src={`/images/works/${item.image}`}
                  alt=""
                />
                <div className="p-4">
                  <h3 className="text-xl font-bold mt-2">
                    {i18n.language === "it" ? item.title : item.titleEn} -&nbsp;
                    {item.material && (
                      <>
                        {i18n.language === "it"
                          ? item.material
                          : item.materialEn}
                      </>
                    )}
                    &nbsp; - &nbsp;{item.productionDate}
                  </h3>
                  {item.youtubeLink && (
                    <button
                      onClick={() => setSelectedYoutube(item)}
                      className="bg-red-600 text-white px-4 py-2 rounded mt-5  flex items-center gap-4"
                    >
                      <FaYoutube /> Video
                    </button>
                  )}
                  {item.link && (
                    <div className="mt-5">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-blue-600 text-white px-4 py-2 rounded"
                      >
                        {t("Link online art show")}
                      </a>
                    </div>
                  )}
                  {!item.youtubeLink && item.video && (
                    <button
                      onClick={() => setSelectedVideo(item)}
                      className="bg-gray-600 text-white px-4 py-2 rounded mt-5 flex items-center gap-4"
                    >
                      <FaVideo /> Video
                    </button>
                  )}
                  <div className="mt-5 text-gray-600">
                    {item.haiku !== "" && (
                      <h5 className="italic">
                        “{i18n.language === "it" ? item.haiku : item.haikuEn}“
                      </h5>
                    )}

                    {item.tribute && <h5 className="italic">{item.tribute}</h5>}
                  </div>
                  <div style={{ marginTop: (item.marginTop || "10") + "px" }}>
                    <h5 className="font-bold">
                      {i18n.language === "it" ? item.subtitle : item.subtitleEn}
                    </h5>
                    <p className="mt-2 whitespace-pre-line">
                      {i18n.language === "it"
                        ? formatTextWithLineBreaks(item.description)
                        : formatTextWithLineBreaks(item.descriptionEn ?? "")}
                    </p>
                  </div>
                </div>
                {item.images && item.images.length === 1 && (
                  <div className="p-4">
                    <img
                      className="w-full"
                      src={item.images[0].original}
                      alt=""
                    />
                  </div>
                )}
                {item.images && item.images.length > 1 && (
                  <div className="p-4">
                    <ImageGallery
                      items={item.images as ReactImageGalleryItem[]}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Modal for YouTube */}
        {modal && selectedItem && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-black w-full max-w-5xl p-4 rounded-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold">
                  {i18n.language === "it"
                    ? selectedItem.title
                    : selectedItem.titleEn}
                </h3>
                <button onClick={toggle} className="text-white text-lg">
                  &times;
                </button>
              </div>
              <YouTube
                iframeClassName="max-h-[400px]"
                videoId={selectedItem.movieId as string | undefined}
                opts={opts}
              />
              <button
                onClick={toggle}
                className="bg-white text-black px-4 py-2 rounded mt-5"
              >
                Close
              </button>
            </div>
          </div>
        )}

        {/* Modal for Video */}
        {modalVideo && selectedItem && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-black w-full max-w-5xl p-4 rounded-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold">
                  {i18n.language === "it"
                    ? selectedItem.title
                    : selectedItem.titleEn}
                </h3>
                <button onClick={toggleVideo} className="text-white text-lg">
                  &times;
                </button>
              </div>
              <Video
                src={selectedItem && selectedItem.video!}
                height="100%"
                width="100%"
                poster={selectedItem && selectedItem.image}
              />
              <button
                onClick={toggleVideo}
                className="bg-white text-black px-4 py-2 rounded mt-5"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Installations;
