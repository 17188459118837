import type { CookieConsentConfig } from "vanilla-cookieconsent";

const pluginConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom right",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "box",
      position: "left",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  onFirstConsent: function () {
    console.log("onFirstAction fired");
  },

  onConsent: function ({ cookie }) {
    console.log("onConsent fired ...");
  },

  onChange: function ({ changedCategories, cookie }) {
    console.log("onChange fired ...");
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
  },

  language: {
    default: "it",

    translations: {
      it: {
        consentModal: {
          title: "Ciao viaggiatore, è tempo di cookie!",
          description:
            'Il nostro sito utilizza cookie per il funzionamento dello stesso. <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Gestisci preferenze</a>',
          acceptAllBtn: "Accetta tutti",
          acceptNecessaryBtn: "Rifiuta tutti",
          showPreferencesBtn: "Gestisci preferenze",
          closeIconLabel: "Chiudi",
          footer: `
            <a href="https://www.iubenda.com/privacy-policy/11805238">Informativa sulla privacy</a>
          `,
        },
        preferencesModal: {
          title: "Preferenze dei cookie",
          acceptAllBtn: "Accetta tutti",
          acceptNecessaryBtn: "Rifiuta tutti",
          savePreferencesBtn: "Salva preferenze",
          closeIconLabel: "Chiudi",
          sections: [
            {
              title: "Utilizzo dei cookie",
              description:
                'Utilizzo i cookie per garantire le funzionalità di base del sito web e per migliorare la tua esperienza online. Puoi scegliere di attivare/disattivare ciascuna categoria in qualsiasi momento. Per ulteriori dettagli relativi ai cookie e ad altri dati sensibili, ti invitiamo a leggere la <a href="#" class="cc__link">informativa sulla privacy</a> completa.',
            },
            {
              title: "Cookie strettamente necessari",
              description: "Descrizione",
              linkedCategory: "necessary",
            },
          ],
        },
      },
      en: {
        consentModal: {
          title: "Hello traveller, it's cookie time!",
          description:
            'Our website uses tracking cookies to understand how you interact with it. The tracking will be enabled only if you accept explicitly. <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Manage preferences</a>',
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          showPreferencesBtn: "Manage preferences",
          //closeIconLabel: 'Close',
          footer: `
            <a href="#link">Privacy Policy</a>
            <a href="#link">Impressum</a>
          `,
        },
        preferencesModal: {
          title: "Cookie preferences",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Save preferences",
          closeIconLabel: "Close",
          sections: [
            {
              title: "Cookie Usage",
              description:
                'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc__link">privacy policy</a>.',
            },
            {
              title: "Strictly necessary cookies",
              description: "Description",
              linkedCategory: "necessary",
            },
          ],
        },
      },
    },
  },
};

export default pluginConfig;
